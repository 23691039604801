import $ from "cash-dom";

$(document).ready(() => {
    const $container = $('#header-animated-text');
    const scroll = $container.find('.animated-text-scroll').get(0);
    const text = $container.find('.animated-text-original').get(0);

    if (text.clientWidth > scroll.clientWidth) {
        const start = Date.now();
        const speed = 25 / 1000;

        const onAnimationFrame = () => {
            const elapsed = Date.now() - start;
            const leftOffset = Math.round(elapsed * speed) % text.clientWidth;

            scroll.scroll({left: leftOffset});

            requestAnimationFrame(onAnimationFrame);
        };

        requestAnimationFrame(onAnimationFrame);
    }
});
